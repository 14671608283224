.im-wrap {
    color: #222;
    box-shadow: 0 0 6px #ebebeb;
    background-color: #fff;
    padding: 18px 0;
    border-radius: 20px;
    margin-bottom: 30px;
    .invite-friend-banner {
        width: 860px;
        height: 200px;
        padding: 30px;
        background-image: url("/th/img/raf/bannerRefer.jpg");
        background-size: 100% 100%;
        text-align: center;
        margin: auto;
        border-radius: 20px;
        & > p {
            border-radius: 15px;
            background-color: #feeaab;
            line-height: 30px;
            width: 116px;
            height: 30px;
            margin: 22px auto 14px;
        }
        & > h2 {
            margin: 0;
            font-weight: bold;
            line-height: 1.4;
        }
    }
    .invite-friend-content {
        padding: 30px;
        width: 900px;
        margin: auto;
        overflow: hidden;
        h4 {
            font-weight: bold;
        }
        .invite-title {
            margin-bottom: 18px;
            color: #222;
        }
        .invite-step-wrap {
            width: 370px;
            float: left;
            & > .ant-btn {
                margin: 10px 0 20px;
                background-color: #00a6ff;
                border-color: #00a6ff;
                &:hover,
                &:focus {
                    background-color: #45a8ff;
                    border-color: #45a8ff;
                }
            }
            .ant-btn-danger[disabled] {
                color: #999 !important;
                background-color: #f0f0f2 !important;
                border: 0 !important;
            }
            .refer-qrcode-wrap {
                p:first-child {
                    font-weight: bold;
                    margin-bottom: 23px;
                }
                .refer-link-title {
                    margin-bottom: 5px;
                }
                .refer-qrcode-title {
                    margin: 20px 0;
                }
                .refer-qrcode-btn {
                    margin: 10px 0 13px;
                }
            }
        }
        .invite-prize-wrap {
            width: 425px;
            float: right;
            .hide-referree-list-btn-box {
                width: 100%;
                text-align: center;
                margin-bottom: 20px;
                .hide-referree-list-btn {
                    width: 200px;
                }
            }
        }
        .invite-list-table {
            width: 100%;
            text-align: center;
            margin-bottom: 20px;
            border-collapse: separate;
            border-spacing: 0;
            .head {
                background-color: #00a6ff;
                color: #fff;
                font-size: 12px;
                font-weight: 500;
            }
            thead {
                tr {
                    height: 40px;
                    border-bottom: 1px solid #e4e4e4;
                }
                th:first-child {
                    border-radius: 12px 0 0 0;
                }
                th:last-child {
                    border-radius: 0 12px 0 0;
                }
            }
            tbody {
                tr {
                    height: 40px;
                    td:first-child {
                        border-left: 1px solid #e0e0e0;
                    }
                    td:last-child {
                        border-right: 1px solid #e0e0e0;
                    }
                    td {
                        border-bottom: 1px solid #e4e4e4;
                    }
                }
                tr:last-child td:first-child {
                    border-radius: 0 0 0 12px;
                }
                tr:last-child td:last-child {
                    border-radius: 0 0 12px 0;
                }
            }
        }
        .prize-example-wrap,
        .question-list {
            margin-bottom: 20px;
            p:first-child {
                font-weight: bold;
            }
        }
        .question-list {
            li {
                margin-bottom: 24px;
                span {
                    text-decoration: underline;
                }
            }
        }
        .prize-example-list {
            line-height: 22px;
        }
        .decimal-list {
            padding-left: 20px;
            & > li {
                list-style: decimal;
                > ul > li {
                    position: relative;
                    padding-left: 12px;
                    &::before {
                        position: absolute;
                        left: 0;
                        content: "*";
                        padding-right: 4px;
                    }
                }
            }
        }
        .prize-referee-list {
            margin-bottom: 10px;
            border-radius: 10px;
            font-size: 12px;
            border: 1px solid #e0e0e0;
            height: 114px;
            position: relative;
            .referee-label {
                position: absolute;
                top: -2px;
                left: 0;
            }
            .referee-number {
                position: absolute;
                color: #ffffff;
                font: bold 18px/21px Roboto;
                padding: 9px;
            }
            .referree-detail-box {
                display: flex;
                gap: 14px;
                justify-content: center;
                align-items: center;
                height: 100%;
                .referree-user-image {
                    width: 65px;
                    height: 65px;
                    background-color: #efeff4;
                    border-radius: 50%;
                }
                .referree-detail-list {
                    width: 212px;
                    display: flex;
                    flex-wrap: wrap;
                    column-gap: 20px;
                    align-items: center;
                    label {
                        width: 82px;
                        color: #999999;
                        line-height: 24px;
                    }
                    p {
                        width: 110px;
                        margin-bottom: 0px;
                        font-size: 14px;
                        font-weight: 500;
                        &:nth-child(6) {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
    .refer-href-link {
        height: 40px;
        line-height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border: 1px solid #e0e0e0;
        border-radius: 10px;
        padding: 0 10px;
    }
    .margin-distance {
        margin-bottom: 10px;
    }
    .prompt-center-box.refer-friend-prompt {
        left: 304px;
        top: 272px;
        right: auto;
        bottom: auto;
        height: 40px;
        line-height: 40px;
        border-radius: 20px;
        &._2 {
            top: 406px;
        }
    }
}
.raf-step-modal {
    .ant-modal-header .ant-modal-title {
        font-size: 16px;
        text-align: center;
        font-weight: bold;
    }
    .ant-modal-body {
        padding: 22px 15px 30px;
        .invite-list-step {
            margin-bottom: 36px;
        }
    }
}
.raf-modal {
    .ant-modal-header .ant-modal-title {
        font-size: 16px;
        text-align: center;
        font-weight: bold;
    }
    .ant-modal-body {
        text-align: center;
        padding: 30px;
        .raf-modal-title {
            margin: 11px 0 9px;
        }
        .raf-btn {
            width: 160px;
            &:last-child {
                margin-left: 20px;
            }
        }
        .ant-modal-confirm-content {
            margin-top: 35px;
        }
        .ant-modal-confirm-btns {
            margin-top: 10px;
            display: flex;
            gap: 20px;
            .ant-btn {
                width: 100%;
                height: 40px;
                margin: 0;
            }
        }
        p {
            color: #222222;
            margin-bottom: 20px;
        }
    }
}
.invite-list-step > li {
    width: 100%;
    line-height: 100px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: 1px solid #e0e0e0;
    position: relative;
    & > div {
        display: inline-block;
    }
    & > i.anticon svg {
        font-size: 18px;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 20px;
        color: #ccc;
    }
    & > i.anticon.success svg {
        color: @success-color;
    }
    .invite-step-number {
        width: 80px;
        font-size: 62px;
        color: #00a6ff;
        vertical-align: middle;
        text-align: center;
    }
    .invite-step-content {
        vertical-align: middle;
        line-height: 1.5;
        max-width: 252px;
        color: #222;
        margin: 10px 0;
        .tlc-sprite {
            width: 14px;
            height: 14px;
            display: inline-block;
            vertical-align: -0.2em;
            margin-right: 6px;
            border-radius: 50%;
            &:nth-child(3) {
                margin-left: 10px;
            }
            &.user-profile {
                background-color: #cccccc;
                background-position: center 3px;
                background-image: url("/th/img/raf/profile.png");
            }
            &.user-phone {
                background-color: #cccccc;
                background-position: -204px -135px;
                background-image: url("/th/img/home/sprite20230330.png");
            }
            &.user-profile.curr,
            &.user-phone.curr {
                background-color: #42d200;
            }
        }
        & > .ant-btn {
            width: 88px;
            height: 27px;
            margin-top: 6px;
            border-radius: 5px;
            font-size: 12px;
            background-color: #00a6ff;
            border-color: #00a6ff;
            &:hover,
            &:focus {
                background-color: #45a8ff;
                border-color: #45a8ff;
            }
        }
        & > h4 {
            margin-bottom: 4px;
            color: #222;
        }
        & > p {
            color: #666;
            font-size: 12px;
            margin: 0;
        }
    }
    .ant-btn-danger[disabled] {
        color: #fff;
        background-color: #cccccc;
        border-color: #bfbfbf;
    }
}
// .invite-list-step.has-login > li {
// 	height: 110px;
// 	line-height: 110px;
// }
.invite-list-step.has-login.learn > li {
    padding-left: 20px;
}
.refer-friend-title {
    font-weight: bold;
    padding-top: 30px;
    padding-bottom: 18px;
}
