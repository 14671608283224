@use autoprefixer;

// 开发过程Header样式错误，强制覆盖
.ant-menu-horizontal {
    border-bottom: 0 !important;
    line-height: inherit;
}

// 帳戶資料General setting
.user-center-wrap {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 6px #ddd;
    margin-bottom: 40px;
    min-height: 936px;
    position: relative;
    overflow: hidden;

    .home-section-title {
        margin-bottom: 20px;
    }

    .ant-form-item-label {
        padding-bottom: 0 !important;
        margin-bottom: 5px !important;
    }

    // 所有input
    .user-center-account-profile-input-item {
        position: relative;

        .user-center-account-profile-input:disabled {
            background-color: #f4f2f2 !important;
            color: #999999;
            border-color: #e0e0e0;
        }

        .user-center-account-profile-input:disabled.realName,
        .user-center-account-profile-input:disabled.cardID {
            color: #999999;
        }

        // 手機信箱未驗證狀態
        .verifiled-status-box {
            position: absolute;
            top: -50%;
            right: 0%;
            font-size: 12px;
            padding: 0 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 68px;
            height: 45px;
            border-radius: 0 10px 10px 0;
            background-color: #00a6ff;
            color: #fff;
            cursor: pointer;

            img {
                width: 14px;
                height: 14px;
            }

            p {
                margin: 0;
                font-weight: bold;
            }
        }
        // 手機信箱已驗證狀態
        .verifiled-status-box.verified {
            color: #34c759;
            width: unset;
            gap: 8px;
            background-color: transparent;
            cursor: default;

            p {
                font-weight: 400;
            }
        }
    }

    // General checkbox setting
    .general-check-box {
        .ant-checkbox-inner {
            border: #fff;
        }

        .ant-checkbox-wrapper:hover .ant-checkbox-inner,
        .ant-checkbox:hover
            .ant-checkbox-inner
            .ant-checkbox.ant-checkbox-checked
            .ant-checkbox-input:focus
            + .ant-checkbox-inner {
            border-color: #fff !important;
        }

        .ant-checkbox-checked::after {
            border-color: #fff;
        }

        // 非disable狀態checkbox style
        .ant-checkbox {
            .ant-checkbox-inner {
                background-color: #ffffff;
                border-color: #fff;
                &::after {
                    border-color: #fff;
                }
            }

            // label style
            & + span {
                color: #222222;
            }
        }

        // disable狀態checkbox style
        .ant-checkbox-checked.ant-checkbox-disabled {
            // label style
            & + span {
                color: #222222;
            }
        }
    }
}
.user-center-userinfo-comfirm-modal {
    .ant-btn {
        color: #00a5fd;
        border: 1px solid #00a5fd;
    }
    .ant-btn:hover,
    .ant-btn:focus {
        color: #29bbff;
        border-color: #29bbff;
    }
    .ant-btn-primary {
        color: #fff;
        background-color: #00a6ff;
        border-color: #00a6ff;
    }
    .ant-btn-primary:hover,
    .ant-btn-primary:focus {
        color: #fff;
    }
}
.left-nav-wrap {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 240px;
    padding: 30px 26px;
    margin: auto;
    color: #fff;
    text-align: center;
    background-color: #00a6ff;
    // background-color: #0d81e6;
    // background-color: #0b79db;
    // background-image: linear-gradient(to bottom, #0b79db, #004798);
}
.usercenter-mask {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 2;
    &.transparent {
        background-color: transparent;
    }
}
.tlc-user-picture {
    width: 80px;
    height: 80px;
    line-height: 2.2;
    margin: auto;
    background-color: @theme;
    border-radius: 50%;
    margin-bottom: 10px;
    overflow: hidden;
}
.tlc-user-picture > img {
    width: 100%;
}
.tlc-user-name {
    color: inherit;
    font-weight: bold;
}
.tlc-user-picture-wrap {
    padding-bottom: 20px;
}
.tlc-user-picture-wrap .user-info-thumb > span:nth-child(2) {
    background-color: #ffffff61;
}
.tlc-user-wallet-wrap {
    padding: 20px 5px;
    border-top: 1px solid #ffffff61;
}
.user-nav-list {
    line-height: 36px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid #ffffff61;
}
.user-nav-list > li {
    position: relative;
    .remind-circle {
        position: absolute;
        top: 8px;
        right: 56px;
    }
    > span {
        cursor: pointer;
    }
}
.user-nav-list > li > span:hover,
.user-nav-list > li > span.active {
    //color: #ffee00;
    font-weight: bold;
    background-color: white;
    padding: 8px 20px;
    color: #00a6ff;
    border-radius: 8px;
}
// .user-nav-list > li:hover::before,
// .user-nav-list > li.active::before {
// 	content: "-　";
// }
// .user-nav-list > li:hover::after,
// .user-nav-list > li.active::after {
// 	content: "　-";
// }
.tlc-all-balance-title,
.tlc-currency-title {
    display: flex;
    justify-content: space-between;
    text-align: left;
    padding: 0 8px;
    padding-bottom: 4px;
}
.tlc-currency-title .anticon {
    opacity: 0.5;
    transition: opacity 0.3s;
    cursor: pointer;
}
.tlc-currency-title .anticon:hover {
    opacity: 1;
}
.tlc-all-balance,
.tlc-currency {
    border-radius: 5px;
    background-color: #fff;
    padding: 3px 10px;
    color: #000;
    border: 1px solid #999;
    font-weight: bold;
    margin-bottom: 8px;
}
.tlc-currency {
    color: #fff;
    background-color: #000;
    border-color: #000;
    box-shadow: 0 0 3px #363646;
}
.tlc-all-balance .inline-block:nth-child(2),
.tlc-currency .inline-block:nth-child(2) {
    width: 80%;
}
.tlc-currency-wrap .small-sign {
    text-align: left;
    margin-bottom: 0;
    font-size: 12px;
    transform: scale(0.84, 0.84);
    *font-size: 10px;
    margin-left: -7px;
}
.tlc-currency-wrap .small-sign.gray {
    color: #999999;
}
.tlc-user-wallet-wrap .ant-btn-group {
    margin-bottom: 20px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
}
.tlc-user-wallet-wrap .ant-btn {
    font-size: 12px;
}
.tlc-user-wallet-wrap .ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.tlc-user-wallet-wrap .ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

// 右侧内容公用样式
.right-content-wrap {
    padding: 30px 50px 30px 290px;
}
.account-wrap > h2 {
    // font-weight: bold;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 6px;
    font-size: 24px;
}
.account-wrap.clear-border > h2 {
    border-bottom: 0;
}

// 個人中心帳戶資料中的每個block之General setting
.user-form-wrap {
    // label 前不顯示紅點
    .ant-form-item-required::before {
        content: "";
        display: none;
    }
    .ant-form-item-label label {
        font-size: 12px;
    }

    .input-box {
        margin-bottom: 16px;
    }

    .ant-form-item {
        .ant-form-explain {
            margin-top: 4px;
            background-color: #fee5e5;
            padding: 10px 16px;
            border-radius: 8px;
            font: normal normal normal 12px/17px "Noto Sans Thai", Arial, san-serif;
        }

        // 下拉式選單General setting
        .select-box {
            .ant-select-selection__rendered {
                > div {
                    width: 100%;
                    text-align: center;
                }

                .ant-select-selection-selected-value {
                    color: #222;
                }
            }
            &.ant-select-disabled {
                .ant-select-selection__rendered
                    .ant-select-selection-selected-value {
                    color: #999999;
                }
            }
        }

        // 首選提問、安全提問下拉式選單
        .safety-question-select-box,
        .account-select-box {
            .ant-select-selection__rendered {
                > div {
                    width: 100%;
                    text-align: start;
                }
            }
        }

        .ant-select-disabled {
            .ant-select-selection {
                background-color: #f4f2f2;
                border-color: #e0e0e0;
            }
        }

        .select-box.ant-select-open {
            position: relative;
            z-index: 1;

            &::before {
                content: "请选择";
                width: 60px;
                height: 20px;
                background-color: transparent;
                position: absolute;
                z-index: 100;
                font-size: 14px;
                color: transparent;
                top: 25%;
                left: 5%;
                text-align: end;
            }

            .ant-select-selection {
                border-radius: 8px 8px 0 0 !important;
                border: 1px solid #cccccc;
                border-width: thin;
                box-shadow: unset;
            }
        }
    }

    .user-info-dob-picker {
        > div {
            > input {
                cursor: default;
                &:disabled {
                    background-color: #f4f2f2;
                    border-color: #e0e0e0;
                    color: #222;
                }
            }

            // calander icon style
            > i {
                background-image: none !important;
                top: 12px !important;
                right: 5px !important;
                cursor: pointer;

                svg {
                    width: 30px;
                    height: 30px;
                    color: #cccccc;
                }
            }
        }
    }

    .user-info-dob-picker.hasDob {
        input::placeholder {
            color: #999;
        }
    }

    .usercenter-button {
        min-height: 75px;
        text-align: right;
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 35px;
        position: relative;
        padding-left: 8px;
        .ant-btn-lg {
            width: 100px;
            padding: 0 10px;
            margin-left: 5px;
        }
        .bottom-sign {
            position: absolute;
            top: 20px;
            left: 0;
            font-size: 12px;
        }
        .btn-submit:disabled {
            background-color: #cccccc;
            color: #fff;
            border-color: #cccccc;
        }
    }
    .ant-form-item {
        margin-bottom: 8px;
    }
}
.bottom-sign {
    color: #999;
    line-height: initial;
    margin: 0;
}
.bottom-sign-2 {
    background-color: #f6f6f6;
    padding: 15px;
}

.edit-disabeld {
    position: relative;
}
.edit-disabeld::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 6;
}
.edit-disabeld.enable::before {
    display: none;
}

// 多选按钮
.user-form-wrap .ant-checkbox-group {
    line-height: inherit;
}
.user-form-wrap .checkbox-correct {
    .ant-form-item-label > label {
        display: flex;
    }
    .ant-form-item-children {
        height: 45px;
        display: block;
    }
}

// 添加银行卡（空状态）
.usercenter-title-link {
    font-size: 14px;
    float: right;
    line-height: 40px;
}
.null-bankcards {
    margin-top: 80px;
    & > p {
        color: #999;
        margin: 16px 0 40px 0;
    }
}

// 添加银行卡（银行卡列表）
@color: #e14c59, #e14c59, #368ee8, #04ad90, #368ee8, #e14c59, #368ee8, #e14c59,
    #e14c59, #e14c59, #04ad90, #04ad90, #368ee8, #e14c59, #f7a93e, #04ad90,
    #e14c59, #e14c59, #f7a93e, #b546db, #f7a93e, #e14c59, #368ee8, #04ad90,
    #04ad90, #368ee8, #04ad90, #f7a93e, #f7a93e, #e14c59, #e14c59, #f7a93e,
    #368ee8, #e14c59, #368ee8, #f7a93e, #f7a93e, #e14c59, #04ad90, #04ad90,
    #368ee8, #e14c59, #f7a93e, #e14c59, #368ee8, #e14c59, #f7a93e, #368ee8,
    #368ee8, #b546db, #e14c59, #368ee8, #e14c59, #f7a93e, #f7a93e, #e14c59,
    #368ee8, #04ad90;
@name: gongshangyinhang, zhaoshangyinhang, zhongguojiansheyinhang,
    zhongguonongyeyinhang, jiaotongyinhang, zhongguoyinhang,
    shanghaipudongfazhanyinhang, guangfayinhang, dongkuannongcunshangyeyinhang,
    zhongxinyinhang, zhongguominshengyinhang, zhongguoyouzhengcuxuyinhang,
    xinyeyinhang, huaxiayinhang, pinganyinhang, nongcunxingyonghezuoshe,
    guangzhouyinhang, nanjingyinhang, guangzhounongcunshangyeyinhang,
    zhongguoguangdayinhang, wenzhouyinhang, chongqingnongcunshangyeyinhang,
    shanghainongshangyinhang, nongcunxingyonghezuoshe, nongcunxingyonghezuoshe,
    xiamenyinhang, nongcunxingyonghezuoshe, zhuhaihuarunyinhang,
    guangdongshengnongxing, beijingyinhang, dongguanyinhang, shanghaiyinhang,
    bohaiyinhang, zheshangyinhang, hangzhouyinhang, ningboyinhang,
    jiangsuyinhang, guangdongnanyueyinhang, nongcunxingyonghezuoshe,
    nongcunxingyonghezuoshe, shenzhenfazhanyinhang, guangfayinhang,
    chengduyinhang, hebeiyinhang, fujianhaixiayinhang, fudianyinhang,
    haerbinyinhang, hankouyinhang, langfangyinhang, zhongguoguangdayinhang,
    dongguanyinhang, zhongyuanyinhang, pingdingshanyinhang, zhengzhouyinhang,
    baoshangyinhang, huishangyinhang, shaoxinyinhang, nongcunxingyonghezuoshe;

.bank-radio-group.ant-radio-group {
    .ant-radio-button-wrapper {
        width: 172px;
        height: 44px;
        text-align: center;
        line-height: 44px;
    }
    margin-bottom: 24px;
}
.bank-list-wrap {
    // overflow: hidden;
    display: flex;
    flex-wrap: wrap;
}
.bank-list-item {
    float: left;
    width: 240px;
    height: 130px;
    margin-right: 30px;
    color: #fff;
    line-height: 1.4;
    margin-bottom: 18px;
    border-radius: 10px;
    padding: 10px;
    background: #3779d9;
    box-shadow: 0 2px 3px #ccc;
    z-index: 1;
    cursor: pointer;
    &:nth-child(3n) {
        margin-right: 0;
    }
    .bank-img {
        width: 40px;
        float: left;
        vertical-align: top;
        border-radius: 50%;
        // background-color: rgba(255, 255, 255, 0.8);
        & > img {
            width: 40px;
            height: 40px;
            vertical-align: top;
        }
    }
    .bank-info {
        width: 140px;
        float: left;
        padding-left: 10px;
        // max-width: 148px;
        white-space: nowrap;
        overflow: hidden;
        & > h3 {
            color: #fff;
            font-size: 14px;
            font-weight: normal;
            margin-top: 0.5em;
            margin-bottom: 0;
        }
        & > p {
            color: #f3f3f3;
        }
        .bank-number {
            margin-top: 5px;
            position: relative;
            z-index: 2;
        }
    }
    .bank-backgroundImg {
        width: 40px;
        float: left;
        .bank-background {
            // position: absolute;
            background-repeat: no-repeat;
            background-size: cover;
            opacity: 0.4;
            width: 40px;
            height: 40px;
            margin-top: 40px;
        }
        .anticon {
            font-size: 16px;
            opacity: 0.8;
            transition: all 0.3s;
            margin-left: 20px;
            &:hover {
                opacity: 1;
            }
        }
    }
    .bank-details {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .default {
        // opacity: 0.7;
        font-size: 12px;
        bottom: 0;
        text-align: center;
        margin-left: 10px;
    }
}
.bank-account-popover {
    .ant-popover-inner .ant-popover-inner-content {
        padding: 16px 12px;
        justify-content: space-between;
        column-gap: 12px;
    }
}
.backgroundcard(@nameItem,@colorItem) {
    .@{nameItem} {
        background-image: linear-gradient(
            130deg,
            color(~`colorPalette("@{colorItem}", 4) `),
            @colorItem
        );
        .bank-background {
            background-image: url("/cn/img/bank/@{nameItem}.png");
        }
    }
}
.loop(@i) when(@i < length(@name)+1) {
    .backgroundcard(extract(@name, @i),extract(@color, @i));
    .loop(@i+1);
}
.loop(1);

.bank-info-modal {
    .item-wrap {
        line-height: 3;
        & > span:nth-child(2) {
            color: #222;
        }
    }
}
// 消息中心
.message-button {
    text-align: right;
    overflow: hidden;
    width: 99.99%;
    & > .ant-btn,
    & > .ant-radio-group {
        margin-left: 10px;
        font-size: 12px;
    }
    .usercenter-title-brief {
        float: left;
        line-height: 1;
        vertical-align: top;
        .ant-radio-group {
            font-size: 12px;
        }
        .ant-radio-button-wrapper {
            vertical-align: top;
            & > span:last-child > span {
                font-size: 12px;
                transform: scale(0.85);
                position: absolute;
                left: 62px;
                top: -7px;
            }
        }
    }
}

// 投注紀錄自訂義時間框
.recordDateRange {
    .ant-calendar {
        width: 352px;
        top: 48px;
        .ant-calendar-input-wrap {
            display: none;
        }
    }
}
.betRecords-dateRange {
    .ant-modal-close-x {
        color: #222222 !important;
        filter: invert(1);
    }
    .ant-modal-header {
        background-color: white !important;
        border-bottom: 0;
        .ant-modal-title {
            color: #222222 !important;
            text-align: center;
            font-size: 20px;
        }
    }
    .ant-modal-body {
        .ant-form {
            .ant-form-item-required {
                &::before {
                    content: "";
                }
            }
            .betRecords-dateRange-hint {
                height: 20px;
                margin-bottom: 16px;
                text-align: center;
                letter-spacing: 0px;
                color: #000000;
                // font-weight: bold;
                border-radius: 10px;
                // padding: 12px 16px;
            }
            label {
                text-align: left;
                letter-spacing: 0px;
                color: #222222;
                font-size: 12px;
                font-weight: 400;
            }
            .ant-calendar-picker .anticon-calendar.ant-calendar-picker-icon {
                background-image: none;
                color: #999999;
                background-color: #d8d8d8;
            }
        }
    }
}
.ant-calendar-ym-select .ant-calendar-header .ant-calendar-year-select {
    color: #000;
}

.message-wrap
    .message-button
    .usercenter-title-brief
    .ant-radio-button-wrapper {
    width: 50%;
    font-size: 12px;
    &:first-child {
        padding: 0 32px 0 20px;
    }
    &:last-child {
        padding: 0 29px 0 11px;
    }
}
.message-list {
    margin-top: 10px;
    margin-bottom: 60px;
}
.teal-color {
    color: #1af1dd;
}
.message-item {
    display: flex;
    border-bottom: 1px solid #e0e0e0;
    .message {
        display: flex;
        & div:first-child {
            align-self: center;
        }
    }
    .message-arrow {
        position: relative;
        // top: 43.63px;
        // margin-left: auto;
        // margin-right: 25.5px;
        width: 100%;
        > i {
            position: absolute;
            top: 43.63px;
            right: 25.5px;
        }
    }
    &.close {
        .message {
            align-items: center;
        }
    }
    &.open {
        .message {
            .message-photo {
                margin-top: 20px;
            }
        }
    }
}
.message-item .anticon {
    transition: all 0.3s;
}
.message-item.open .anticon {
    transform: rotate(-180deg);
}
.message-item.close .anticon {
    transform: rotate(0deg);
}
.message-item.open .message .message-title-inner-wrap .message-title {
    white-space: normal !important;
}
.message-item.open .message .message-title-wrap h3 {
    white-space: normal !important;
}
.message-item .message {
    padding: 15px 0px 15px 20px;
    line-height: 90px;
    position: relative;
    cursor: pointer;
    & > div {
        vertical-align: middle;
    }
    .message-remind-circle {
        width: 8px;
        height: 8px;
        background-color: #fff;
        border-radius: 50%;
        margin-right: 20px;
    }
    .message-photo {
        background-repeat: no-repeat;
        width: 40px;
        height: 40px;
        margin-right: 16px;
        vertical-align: top;
        flex-shrink: 0;
    }
    // .message-arrow {
    // 	position: absolute;
    // 	top: 15px;
    // 	bottom: 0;
    // 	right: 20px;
    // 	margin: auto;
    // }
    .message-title-wrap {
        line-height: 1.7;
        vertical-align: top;
        margin-top: 4px;
        max-width: 600px;
        width: 600px;
        & > h3 {
            color: #000;
            margin-bottom: 0;
            max-width: 600px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .message-title-inner-wrap {
            margin-bottom: 0;
            max-width: 600px;
            overflow: hidden;
            line-height: 24px;
            transition: height 0.3s;
            .message-title {
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
    &.unread .message-remind-circle {
        background-color: #f53d3d;
        flex-shrink: 0;
    }
    &.bank .message-photo {
        background-image: url("/th/img/user/message-banking.png");
    }
    &.bank .message-title-wrap > h3 > span {
        color: #78909c;
    }
    &.game .message-photo {
        background-image: url("/th/img/user/message-product.png");
    }
    &.game .message-title-wrap > h3 > span {
        color: #00acc1;
    }
    &.withdraw .message-photo {
        background-image: url("/th/img/user/message-withdraw.png");
    }
    &.withdraw .message-title-wrap > h3 > span {
        color: #78909c;
    }
    &.promotion .message-photo {
        background-image: url("/th/img/user/message-promo.png");
    }
    &.promotion .message-title-wrap > h3 > span {
        color: #78909c;
    }
    &.transfer .message-photo {
        background-image: url("/th/img/user/message-transfer.png");
    }
    &.transfer .message-title-wrap > h3 > span {
        color: #78909c;
    }
    &.general .message-photo {
        background-image: url("/th/img/user/message-general.png");
    }
    &.general .message-title-wrap > h3 > span {
        color: #78909c;
    }
    &.bonus .message-photo {
        background-image: url("/th/img/user/message-bonus.png");
    }
    &.bonus .message-title-wrap > h3 > span {
        color: #78909c;
    }
    &.product .message-photo {
        background-image: url("/th/img/user/message-product.png");
    }
    &.product .message-title-wrap > h3 > span {
        color: #78909c;
    }
    &.personal .message-photo {
        background-image: url("/th/img/user/message-personal.png");
    }
    &.personal .message-title-wrap > h3 > span {
        color: #78909c;
    }
    &.transition .message-photo {
        background-image: url("/th/img/user/message-inbox.png");
    }
}

// 我的优惠
.my-promo-list-wrap {
    margin: 20px 0;
    .my-promo-item {
        border-radius: 10px;
        border: 1px solid #e0e0e0;
        padding: 15px;
        margin-bottom: 10px;
        h3,
        p {
            margin: 0;
            vertical-align: middle;
        }
        .promo-brief {
            padding-bottom: 10px;
            border-bottom: 1px solid #f0f0f2;
            position: relative;
            & > div {
                vertical-align: middle;
            }
        }
        .brief-img {
            width: 80px;
            height: 50px;
            border-radius: 6px;
            background-color: #e0e0e0;
            margin-right: 20px;
            overflow: hidden;
            & > img {
                width: 100%;
            }
        }
        .brief-info {
            & > h3 {
                color: #222;
                padding-right: 30px;
            }
            & > p {
                color: #999;
            }
        }
        .brief-detail {
            width: 300px;
            &.light > h3 {
                color: @theme;
            }
            &.light .line-process {
                background-color: @theme;
            }
            &.dark > h3 {
                color: #999;
            }
            &.dark .line-process {
                background-color: #999;
            }
        }
        .brief-detail-wrap {
            margin-top: 10px;
        }
        .line-process-wrap {
            border-radius: 2px;
            height: 4px;
            width: 100%;
            margin-bottom: 6px;
            background-color: #f5f5f5;
            position: relative;
            overflow: hidden;
            .line-process {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 50%;
                &.light {
                    background-color: @theme;
                }
                &.dark {
                    background-color: #999;
                }
            }
        }
        .brief-button {
            float: right;
        }
        .anticon {
            font-size: 22px;
            cursor: pointer;
            color: #828282;
            position: absolute;
            right: 0;
            top: 0;
        }
        .ant-btn {
            margin-top: 7px;
            width: 146px;
        }
        .item-wrap {
            & > span:nth-child(2) {
                color: #222;
            }
        }
    }
}

.remove-promo-wrap .ant-dropdown-menu {
    padding: 0;
    overflow: hidden;
    .ant-dropdown-menu-item:hover {
        color: @theme;
    }
}
.remove-promo-wrap.small .ant-dropdown-menu .ant-dropdown-menu-item,
.remove-promo-wrap.small .ant-dropdown-menu .ant-dropdown-menu-submenu-title {
    line-height: 26px;
    padding: 5px 22px;
}

// 我的返水
.my-bonus-list-wrap {
    margin-top: 20px;
    line-height: 1.4;
    .my-bonus-item {
        cursor: pointer;
        border-radius: 10px;
        border: 1px solid #e0e0e0;
        padding: 15px;
        margin-bottom: 10px;
        .bonus-brief {
            padding-bottom: 10px;
            border-bottom: 1px solid #f0f0f2;
            h3 {
                margin: 0;
                font-size: 14px;
                font-weight: 500;
            }
            p {
                margin: 0;
                vertical-align: middle;
            }
            & > div {
                vertical-align: middle;
            }
            .time-interval {
                font-size: 12px;
            }
        }
        .anticon {
            float: right;
            line-height: 40px;
            color: #ccc;
        }
        .brief-detail-wrap {
            padding-top: 20px;
        }
        .brief-detail {
            width: 50%;
            text-align: center;
            & > p {
                color: #666;
            }
            & > strong {
                font-weight: 500;
            }
        }
        .brief-info > h3 {
            font-weight: bold;
        }
        .brief-img {
            width: 40px;
            height: 40px;
            margin-right: 10px;
            & > img {
                width: 100%;
            }
        }
    }
}
.no-betRecord {
    margin-top: 140px;
}
// 记录列表
.records-list-wrap {
    margin-top: 30px;
    margin-bottom: 50px;
    text-align: center;
    &._modal {
        margin-top: 0;
        margin-bottom: 20px;
    }
    .ant-row {
        padding: 10px 0;
        line-height: 1.7;
        min-height: 40px;
        border-bottom: 1px solid #f3f3f3;
        color: #222;
        &:not(:first-child) {
            font-size: 12px;
        }
        &.withdraw-break {
            .ant-col {
                white-space: nowrap;
                text-overflow: ellipsis;
                display: inline-block;
                vertical-align: middle;
                float: none;
                &:last-child {
                    text-align: left;
                    line-height: 1.4;
                    white-space: normal;
                }
            }
        }
        .ant-col-3 {
            &:last-child {
                line-height: 25px;
            }
        }
    }
    .ant-col {
        overflow: hidden;
        // white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
        vertical-align: middle;
        float: none;
        .small-sign {
            padding-left: 14px;
            transform: scale(0.9);
            transform-origin: 0 0;
            color: #999;
        }
        .anticon {
            transform: rotate(90deg);
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            right: 10px;
            width: 14px;
            height: 14px;
        }
        .open {
            display: none;
            &::before {
                content: "";
                width: 2px;
                height: calc(100% - 45px);
                background-color: #ccc;
                position: absolute;
                left: 23px;
                top: 10px;
            }
            &._short::before {
                height: calc(100% - 25px);
            }
        }
        .close {
            display: block;
        }
        .small-circle {
            color: #999;
            position: relative;
            padding: 5px 5px 5px 14px;
            &::before {
                content: "";
                display: block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: #ccc;
                position: absolute;
                top: 10px;
                left: 0;
                outline-offset: 0.1rem;
                outline: solid 1px #ccc;
            }
        }
        .record-btn-wrapper {
            display: flex;
            flex-wrap: wrap;
            gap: 6px;
            margin-top: 10px;
        }
        .record-btn {
            border-radius: 5px;
            font-size: 10px;
            width: 100px;
            line-height: 24px;
            height: 26px;
            &.ant-btn-default:focus {
                border: 1px solid #ccc;
                color: #666;
            }
            &.ant-btn-default:hover {
                border: 1px solid #ff5c57;
                color: #ff5c57;
            }
        }
        .ant-btn-confirmReceipt {
            background-color: #1c8eff;
            color: #ffffff;
            &:hover,
            &:focus {
                border: 1px solid #1c8eff;
            }
        }
        &.record-distance {
            padding: 0 6px;
            .reason-msg {
                text-align: left;
                white-space: normal;
                line-height: 1.4;
            }
        }
        &.r-pending {
            .close .small-circle,
            .open._1 .small-circle:nth-child(1),
            .open._2 .small-circle:nth-child(3),
            .open._3 .small-circle:nth-child(5) {
                color: #1c8eff;
                &::before {
                    background-color: #1c8eff;
                    outline-offset: 0.1rem;
                    outline: solid 1px #1c8eff;
                }
            }
        }
        &.r-expired {
            .close .small-circle,
            .open._1 .small-circle:nth-child(1),
            .open._2 .small-circle:nth-child(3),
            .open._3 .small-circle:nth-child(5) {
                color: #f92d2d;
                &::before {
                    background-color: #f92d2d;
                    outline-offset: 0.1rem;
                    outline: solid 1px #f92d2d;
                }
            }
        }
        &.r-process {
            .close .small-circle,
            .open._1 .small-circle:nth-child(1),
            .open._2 .small-circle:nth-child(3),
            .open._3 .small-circle:nth-child(5) {
                color: #ff8f00;
                &::before {
                    background-color: #ff8f00;
                    outline-offset: 0.1rem;
                    outline: solid 1px #ff8f00;
                }
            }
        }
        &.r-success {
            .close .small-circle,
            .open._1 .small-circle:nth-child(1),
            .open._2 .small-circle:nth-child(3),
            .open._3 .small-circle:nth-child(5) {
                color: #41d100;
                &::before {
                    background-color: #41d100;
                    outline-offset: 0.1rem;
                    outline: solid 1px #41d100;
                }
            }
        }
        &.r-error {
            .close .small-circle,
            .open._1 .small-circle:nth-child(1),
            .open._2 .small-circle:nth-child(3),
            .open._3 .small-circle:nth-child(5) {
                color: #f92d2d;
                &::before {
                    background-color: #f92d2d;
                    outline-offset: 0.1rem;
                    outline: solid 1px #f92d2d;
                }
            }
        }
        &.left {
            padding-left: 20px;
            text-align: left;
        }
        &.true {
            .anticon {
                transform: rotate(-90deg);
                top: -70%;
            }
            .open {
                display: block;
            }
            .close {
                display: none;
            }
        }
    }
    .record-info-icon {
        margin-left: 5px;
        display: inline-block;
        vertical-align: sub;
        width: 16px;
        height: 16px;
        background-image: url(/th/img/user/icon-detail.svg);
        background-size: contain;
    }
    .ant-btn.inline.ant-btn-link {
        font-size: 12px;
        margin-left: 2px;
    }
    .anticon {
        color: #999;
    }
    .transfer-to {
        img {
            padding: 0 5px;
        }
    }
    .big-empty-box {
        margin-top: 100px;
    }
}
// 记录详情
// .records-receipt {
//   padding-left: 24px;
//   padding-right: 24px;
//   .tlc-sprite {
//       float: left;
//       width: 28px;
//       height: 24px;
//       line-height: 24px;
//       margin-top: -2px;
//   }
//   .receipt-type {
//       padding-left: 36px;
//   }
//   .item-wrap {
//       line-height: 1.7;
//   }
//   .USDT-Reason {
//     width: 80%;
//     text-align: right;
//     display: inline-block;
//     white-space: pre-wrap;
//   }
//   & > li {
//       padding: 10px 15px;
//       border-bottom: 1px solid #E0E0E0;
//       &:last-child {
//         border-bottom: 0;
//       }
//       span:last-child {
//           color: #222;
//       }
//   }
// }
.records-modal {
    .ant-modal-content {
        border-radius: 20px;
        overflow: hidden;
    }
    .ant-modal-header .ant-modal-title {
        text-align: center;
        font-size: 20px;
    }
    .ant-modal-body {
        padding: 0;
    }
}
.records-bankinfo-modal {
    .ant-modal-content {
        border-radius: 20px;
        overflow: hidden;
        .ant-modal-header .ant-modal-title {
            text-align: center;
            font-size: 20px;
            border-bottom: 0px;
        }
        .ant-modal-body {
            padding: 28px 24px;
        }
        .ant-modal-footer {
            padding: 0px 30px 28px;
        }
    }
    .bankNameItem {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 12px;
        > :first-child {
            width: 14px;
            height: 14px;
        }
        > :last-child {
            max-width: 85%;
        }
    }
    .usdt-bankItem {
        flex-wrap: wrap;
        row-gap: 16px;
        &.CTCItem {
            row-gap: 8px;
            > div:first-child {
                width: 30%;
            }
            > div:nth-child(2) {
                width: 70%;
                text-align: right;
            }
        }
        .greyNote {
            background-color: #f5f5f5;
            border-radius: 8px;
            padding: 10px 20px;
            font-size: 12px;
            color: #666666;
            width: 100%;
            .Tier2 {
                padding-left: 82px;
            }
        }
    }
}
// .receipt-process-wrap {
//   padding: 20px 39px;
//   border-top: 2px dashed #E0E0E0;
//   li {
//     height: 40px;
//     line-height: 40px;
//     color: #999;
//     .time-sign {
//       color: #999;
//       float: right;
//     }
//     .receipt-point {
//       width: 10px;
//       height: 10px;
//       border: 3px solid #ccc;
//       background-color: #fff;
//       position: relative;
//       border-radius: 50%;
//       margin-right: 15px;
//       &::before, &::after {
//         content: "";
//         display: block;
//         width: 2px;
//         left: 1px;
//         height: 16px;
//         background-color: #E0E0E0;
//         position: absolute;
//       }
//       &::before {
//         top: -19px;
//       }
//       &::after {
//         top: 6px;
//       }
//     }
//     &:first-child {
//       .receipt-point::before {
//         content: none;
//       }
//     }
//     &:last-child {
//       .receipt-point::after {
//         content: none;
//       }
//     }
//   }
//   .step-1 li:first-child {
//     .process-brief {
//       color: #1c8eff;
//     }
//     .receipt-point {
//       background-color: #1c8eff;
//       border-color: #1c8eff;
//     }
//   }
//   .step-2 li:last-child {
//     .process-brief {
//       color: #42D200;
//     }
//     .receipt-point {
//       background-color: #42D200;
//       border-color: #42D200;
//     }
//   }
//   .step-3 li:last-child {
//     .process-brief {
//       color: #F92D2D;
//     }
//     .receipt-point {
//       background-color: #F92D2D;
//       border-color:#F92D2D;
//     }
//   }
// }

// 自定义时间显示框
.usercenter-title-tools {
    .ant-select-selection {
        width: 100px;
    }
    float: right;
    display: flex;
}
.account-wrap {
    .defined-time-wrap {
        text-align: end;

        margin-top: 15px;
        cursor: pointer;
        vertical-align: top;
        transform-origin: 0 0;
        transform: translateZ(0);
        .anticon {
            color: #999;
            font-size: 16px;
            padding: 0 10px;
            &:last-child {
                padding-right: 0;
            }
        }
    }
}

// General Setting 灰色方框內容
.security-code-wrap {
    width: min-content;
    height: auto;
    background: #f6f6f6 0% 0% no-repeat padding-box;
    border-radius: 10px;
    margin: 50px auto;
    padding: 26px 40px 50px 40px;
    text-align: center;
    & > p {
        width: 360px;
        margin: 0 auto 25px auto;
        text-align: left;
        color: #222222;
        font-size: 14px;
        line-height: 22px;
        padding: 0 5px;
    }
    .ant-btn.ant-btn-primary.security-code-btn {
        color: #fff;
        padding: 0 120px;
        width: 100%;
        font-size: 16px;
        // border-color: #fb6262;
        // box-shadow: 0 3px 5px #ba0000;

        &.tlc-button-disabled {
            border-color: #dbdbdb;
            background-color: #b9b9b9;
            text-shadow: none;
            box-shadow: 0 3px 5px #a0a0a0;
        }
    }
}

.security-realm.border {
    // transform: translateY(-20px);

    .code-box {
        width: 386px;
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 30px;
        position: relative;

        .copy-result-box {
            background-color: #daffe3;
            position: absolute;
            z-index: 100;
            display: flex;
            gap: 10px;
            border-radius: 64px;
            padding: 17px 20px;
            align-items: center;
            left: 50%;
            top: 15%;
            transform: translateX(-50%);
            box-shadow: 0px 3px 6px #00000029;

            img {
                width: 24px;
                height: 24px;
            }

            p {
                color: #0ccc3c;
                font-size: 16px;
                margin: 0;
            }
        }

        .security-code {
            cursor: pointer;
            display: flex;
            position: relative;
            gap: 10px;
            justify-content: center;

            .code-letter {
                width: 50px;
                height: 50px;
                background-color: #fff;
                color: #0ccc3c;
                border-radius: 6px;
                font-size: 30px;
                font-weight: bold;
            }

            .anticon {
                font-size: 26px;
                color: #cbcbcb;
                vertical-align: 0;
            }
        }

        .btn-copy {
            border: none;
            background-color: transparent;
            cursor: pointer;
        }
    }
    .countdown-message {
        color: #999999;
        margin-bottom: 30px;
    }

    .line-distance {
        height: 0;
    }

    .still-valid-text {
        color: #83630b;
        background-color: #fff5bf;
        border-radius: 8px;
        padding: 12px 10px;
        text-align: left;
        margin-bottom: 30px;
        font-size: 12px;
    }

    .btn-create-code {
        width: 100%;
        font-size: 16px;
        font-weight: bold;
    }

    .btn-create-code:disabled {
        background-color: #cccccc;
        color: #fff;
        border: none;
    }

    .expired-reminder-box {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;

        p {
            color: #ee0001;
            margin-bottom: 30px;
        }
    }
}

//泰达币钱包
.add-wallet-item {
    float: left;
    width: 240px;
    height: 130px;
    margin-right: 0px;
    color: #58c5ff;
    text-align: center;
    line-height: 108px;
    margin-bottom: 18px;
    border-radius: 10px;
    padding: 10px;
    background: #fff;
    border: 2px dashed #58c5ff;
    cursor: pointer;
}

//泰达币钱包
.wllet-list-item {
    float: left;
    width: 240px;
    height: 130px;
    position: relative;
    margin-right: 30px;
    color: #fff;
    line-height: 1.4;
    margin-bottom: 18px;
    border-radius: 10px;
    padding: 10px;
    background: #26a17b;
    box-shadow: 0 2px 3px #ccc;
    cursor: pointer;
    &:nth-child(3n) {
        margin-right: 0;
    }
    .bank-img {
        vertical-align: top;
        border-radius: 50%;
        & > img {
            width: 40px;
            height: 40px;
            vertical-align: top;
        }
    }
    .bank-info {
        padding-left: 10px;
        max-width: 148px;
        white-space: nowrap;
        & > h3 {
            color: #fff;
            font-size: 14px;
            font-weight: normal;
            margin-top: 0.5em;
            margin-bottom: 0;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        & > p {
            color: #f3f3f3;
        }
    }
    .bank-number {
        position: relative;
        z-index: 2;
        font-size: 10px;
        white-space: initial;
        overflow-wrap: break-word;
        padding: 8px 0;
        height: 55px;
    }
    .copyBtn {
        width: 70px;
        height: 24px;
        margin: auto;
        line-height: 24px;
        text-align: center;
        color: #26a17b;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 14px;
        opacity: 1;
        font-size: 12px;
    }
    .default {
        width: 100%;
        // height: 16px;
        line-height: 16px;
        // opacity: 0.7;
        font-size: 12px;
        text-align: left;
        margin-left: 10px;
    }
    .anticon {
        position: absolute;
        right: 12px;
        top: 12px;
        font-size: 16px;
        opacity: 0.8;
        transition: all 0.3s;
        &:hover {
            opacity: 1;
        }
    }
}
.account-wrap {
    .walletTip {
        width: 100%;
        text-align: center;
        // margin-top: 350px;
        color: #999999;
        overflow: hidden;
        .chatCs {
            color: #00a6ff;
            cursor: pointer;
        }
    }
    .walletTips {
        width: 100%;
        text-align: center;
        color: #999999;
        border-bottom: 1px solid #f0f0f0;
        padding-bottom: 1em;
        .chatCs {
            color: #00a6ff;
            cursor: pointer;
        }
    }
    .bank-account-limit {
        width: 100%;
        height: 135px;
        border: 1px solid #e0e0e0;
        border-radius: 10px;
        margin: 20px auto;
        p {
            width: 94%;
            margin: 0 3%;
            height: 30px;
            line-height: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;
            color: #666666;
        }
        p:first-child {
            margin-top: 7.5px;
            span:first-child {
                font-size: 14px;
                color: #222222;
            }
            span:last-child {
                color: #f92d2d;
                font-size: 12px;
                cursor: pointer;
            }
        }
        p:last-child {
            font-size: 12px;
            color: #666666;
            margin-bottom: 7.5px;
        }
    }
}
.showInfoModal {
    width: 150px !important;
    .ant-modal-confirm-btns {
        display: none;
    }
    &._initail {
        width: 400px !important;
    }
    &.opacity {
        .ant-modal-content {
            background-color: transparent;
        }
        .ant-modal-body {
            background-color: white;
            padding: 20px 20px;
        }
    }
    &.depositLoading {
        width: 250px !important;
        .ant-modal-body {
            padding: 0 !important;
            .loadimg {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 25px;
                p {
                    font-size: 16px;
                    color: #fff;
                    text-align: center;
                }
            }
        }
    }
    &.hidden-btn .ant-modal-confirm-btns {
        margin-top: 20px;
    }
    .ant-modal-body {
        padding: 0;
        background-color: #000000cc;
        border-radius: 6px;
        .ant-modal-confirm .ant-modal-body {
            padding: 0;
        }
        .ant-modal-confirm-body .ant-modal-confirm-content {
            padding: 0;
            //margin-top: 25px;
            color: #222;
            margin-top: 0;
        }
    }

    .ant-btn-primary,
    .ant-modal-close-x {
        display: none;
    }
}

.showInfoColorModal {
    max-width: 150px !important;
    min-width: 100px !important;
    .anticon {
        display: none;
    }
    &.true {
        .ant-modal-content {
            border-radius: 2rem;
            background: #daffe3 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            opacity: 1;
        }
        .ant-modal-body {
            background: transparent;
        }
    }
    &.false {
        .ant-modal-content {
            border-radius: 2rem;
            background: #fadede 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            opacity: 1;
        }
        .ant-modal-body {
            background: transparent;
        }
    }
    .ant-modal-body {
        padding: 0 !important;
        .ant-modal-confirm .ant-modal-body {
            padding: 0;
        }
        .ant-modal-confirm-body .ant-modal-confirm-content {
            padding: 0;
            margin-top: 0px !important;
        }
    }

    .ant-btn-primary,
    .ant-modal-close-x {
        display: none;
    }
    div {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-size: 15px;
        padding: 0.1rem;
    }
}

.ant-modal.closable-hide .ant-modal-close {
    display: none;
}

// 2021/09/16 交易记录新版本内容
.t-resubmit-list {
    li {
        border-bottom: 1px solid #e0e0e0;
        // height: 46px;
        // line-height: 46px;
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 12px;
        padding-bottom: 12px;
        > div:first-child {
            width: 40%;
            color: #666;
            // float: left;
        }
        > div:last-child {
            width: 60%;
            color: #222222;
            // float: right;
            text-align: right;
        }
        &.t-resubmit-tip {
            background: #fff5bf;
            color: #83630b;
            border-radius: 8px;
            padding: 12px;
            border: 0;
            line-height: 1.4;
            height: auto;
            margin-top: 24px;
            font-size: 12px;
        }
    }
    // li:nth-of-type(3){
    //     border-bottom: 0 !important;
    // }
    .CTCWalletAddressItem {
        height: auto;
        padding: 12px 0;
        > div:first-child {
            width: 30%;
        }
        > div:last-child {
            width: 70%;
        }
    }
    .CCItem {
        > div:first-child {
            width: 50%;
        }
        > div:last-child {
            width: 50%;
        }
    }
}
.t-resubmit-list2 {
    .greyWord {
        background: #efeff4;
        font-size: 12px;
        padding: 0rem 0.5rem;
        border-radius: 0.2rem;
        line-height: 22px;
        margin: 0 0.4rem;
    }
    li {
        color: #666 !important;
        border-bottom: none !important;
        height: 36px;
        // line-height: 46px;
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
        > div:first-child {
            width: 30%;
            // color: #666;
            // float: left;
        }
        > div:last-child {
            width: 70%;
            // float: right;
            text-align: right;
            display: flex;
            justify-content: flex-end;
        }
        &.t-resubmit-tip {
            background: #fff5bf;
            color: #83630b;
            border-radius: 8px;
            padding: 18px;
            border: 0;
            line-height: 1.4;
            height: auto;
            margin-top: 14px;
        }
        &:last-child {
            // border: 0;
        }
        .record-btn {
            font-size: 12px !important;
            width: 4rem;
        }
    }
}
.confirmModalRecord {
    .ant-modal-close-x {
        background-image: url(/cn/img/icon/icon-close.svg);
        background-repeat: no-repeat;
        background-position: center;
        color: transparent;
    }
    .anticon-info-circle {
        display: none;
    }
}
.w-amount-tip {
    margin: 0;
    padding: 0;
    font-size: 12px;
    color: #999;
}
.lbwicon-success {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: inline-block;
    margin-bottom: 20px;
    img {
        width: 100%;
        height: 100%;
    }
}
.withdrawRecordsInfo-modal {
    width: 150px !important;
    &.opacity {
        .ant-modal-content {
            background-color: transparent;
        }
        .ant-modal-body {
            background-color: rgba(0, 0, 0, 0.7);
        }
    }
    &.hidden-btn .ant-modal-confirm-btns {
        margin-top: 20px;
    }
    .ant-modal-body {
        padding: 0 !important;
        background-color: #000000cc;
        border-radius: 6px;
        .ant-modal-confirm .ant-modal-body {
            padding: 0;
        }
        .ant-modal-confirm-body .ant-modal-confirm-content {
            padding: 0;
            margin-top: 25px;
            color: #fff;
        }
    }

    .ant-btn-primary,
    .ant-modal-close-x {
        display: none;
    }
}

.Progress {
    overflow: hidden;
    height: 10px;
    background-color: #f3f5f9;
    // background-image: linear-gradient(to bottom, #f5f5f5, #f9f9f9);
    background-image: linear-gradient(to bottom, #f3f5f9, #f3f5f9);
    background-repeat: repeat-x;
    // box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
    border-radius: 8px;
    margin-top: 10px;

    .Bar {
        width: 0%;
        height: 100%;
        color: #ffffff;
        border-radius: 8px;
        font-size: 12px;
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
        // text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
        background-repeat: repeat-x;
        // box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
        box-sizing: border-box;
        transition: width 0.6s ease;
    }
    .redBar {
        background-color: #ee0001;
        background-image: linear-gradient(to bottom, #ee0001, #ee0001);
    }
    .greenBar {
        background-color: #42d200;
        background-image: linear-gradient(to bottom, #42d200, #42d200);
    }
    .yellowBar {
        background-color: #f0a800;
        background-image: linear-gradient(to bottom, #ffbf58, #ffbf58);
    }
}

.record-radio-wrapper {
    .ant-radio-button-wrapper {
        width: 80px;
        text-align: center;
    }
}

.SecurityCheck {
    .TypeName {
        font: normal normal normal 12px/16px "Noto Sans Thai", Arial, san-serif;
        letter-spacing: 0px;
        color: #999999;
        padding-bottom: 10px;
    }
    .txt {
        color: #999999;
        padding: 10px 0;
        display: block;
    }
    .note {
        color: #666666;
        font-size: 14px;
    }
    .CheckBox {
        display: flex;
        justify-content: space-between;
        width: 68%;
        margin-top: 30px;
    }
    .List {
        width: 160px;
        height: 197px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #cccccc;
        border-radius: 10px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        line-height: 30px;
    }
    .Progress {
        width: 254px;
    }
    .iconChecked {
        color: #0ccc3c;
        img {
            margin-right: 5px;
        }
    }
}
.UploadFilesUserCenter {
    .UploadFilesBox {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column;
        > p {
            font-size: 18px;
            color: #999999;
            margin-top: 20px;
        }
    }
}
.UploadFileModal.modal-pubilc {
    .ant-modal-content .ant-modal-body {
        padding: 30px;
        color: #222222;
    }
}
//投注紀錄 popover
.betRecords-icon-popover {
    p {
        font-size: 11px;
        margin-bottom: 14px;
    }
}

// 投注紀錄 下拉選單
.betRecords-select {
    width: 102px;
    text-align: center;
    margin-right: 10px !important;
    .ant-select-selection {
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #e0e0e0;
        border-radius: 5px;
        color: #222222;
    }
    .ant-select-selection__rendered {
        margin-left: 8px !important;
        .ant-select-selection-selected-value {
            width: 100%;
        }
    }
    .ant-select-open {
        .ant-select-selection {
            border: 1px solid #e0e0e0 !important;
        }
    }
}
.ant-popover.betRecords-popover-dark .ant-popover-inner-content {
    color: #fff;
    opacity: 1;
}
.ant-popover.betRecords-popover-dark
    > .ant-popover-content
    > .ant-popover-arrow {
    border-top-color: #2c2c2c;
    border-left-color: #2c2c2c;
    left: 23px;
}
.betRecords-popover-dark {
    .ant-popover-inner-content {
        padding: 15px;
    }
}
.betRecord-message-button {
    display: flex;
    justify-content: end;
    align-items: center;
    .ant-select-dropdown-menu-item {
        padding: 8px 29px !important;
    }
    .ant-select-dropdown-menu-item .ant-select-dropdown-menu-item-active {
        background-color: #0000000a !important;
    }
    .ant-select-dropdown-menu-item-selected {
        background-color: #0000000a !important;
    }
}
.ant-select-dropdown-menu-item-active:not(
        .ant-select-dropdown-menu-item-disabled
    ) {
    background-color: #0000000a !important;
}

// 投注紀錄 日期選擇框
.disabled-date {
    .ant-calendar-disabled-cell.ant-calendar-selected-day
        .ant-calendar-date::before {
        background: none;
    }
}

// 投注紀錄 radio-group
.betRecord-radio-group {
    .ant-radio-button-wrapper {
        min-width: 76px;
        padding: 8px;
        text-align: center;
        letter-spacing: 0px;
        color: #222222;
        opacity: 1;
        line-height: 16px;
    }
}
.betRecord-radio-group.ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: white;
    user-select: none;
}
// 投注紀錄 pagination
.betrecord-pagination.ant-pagination {
    .ant-pagination-item,
    .ant-pagination-item-link {
        border-color: #e0e0e0;
    }
    .ant-pagination-item-active {
        border-color: #f0f0f2;
        background-color: #f0f0f2;
        a {
            color: #222;
        }
    }
    .ant-pagination-item:focus,
    .ant-pagination-item:hover,
    .ant-pagination-prev:focus .ant-pagination-item-link,
    .ant-pagination-next:focus .ant-pagination-item-link,
    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link {
        color: #222;
        border-color: #888;
        a {
            color: #222;
        }
    }
    .ant-pagination-disabled .ant-pagination-item-link {
        &:hover {
            border-color: #e0e0e0;
            color: #cccccc;
        }
        color: #cccccc;
    }
}

// 客製無法創建安全碼Modal
.security-code-reminder-modal {
    .ant-modal-content {
        border-radius: 20px;

        .ant-modal-header {
            .ant-modal-title {
                font-size: 18px;
                text-align: center;
                font-weight: 700;
            }
            div {
                color: #fff;
            }
        }

        .ant-modal-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px 30px;

            img {
                margin-bottom: 20px;
            }

            .sub-title {
                display: block;
                margin-bottom: 10px;
                font: normal normal 600 16px/24px "Noto Sans Thai", Arial, san-serif;
                letter-spacing: 0px;
                color: #222222;
                font-weight: 500;
            }

            p {
                margin: 0;
                text-align: center;
                font: normal normal normal 14px/18px "Noto Sans Thai", Arial, san-serif;
                color: #222222;
            }
        }

        .ant-modal-footer {
            padding: 0 28px 28px 28px;
            button:first-child {
                color: #00a6ff;
                border-color: #00a6ff;
                margin-right: 10px;
                &:hover,
                &:focus {
                    color: #29bbff;
                    border-color: #29bbff;
                }
            }
            button {
                width: 48%;
                margin: 0;
                height: fit-content;

                span {
                    display: block;
                    padding: 7px 0;
                }
            }
        }
    }
}

// 客製 帳戶資料 - 基本訊息 - 性別select
.usercenter-dropdown {
    // display: block !important;
    border-radius: 0 0 8px 8px !important;
    box-shadow: unset !important;
    border: 1px solid #cccccc;
    border-top: unset;

    .ant-select-dropdown-menu-item-selected {
        background-color: transparent;
        font-weight: unset;
    }
}

// 客製出生日期Date Picker
.user-info-dob-picker-dropdown {
    z-index: 99;
    .ant-calendar-input-wrap {
        display: none;
    }

    .ant-calendar-date-panel {
        .ant-calendar-header {
            > div {
                > a::before,
                > a::after {
                    border: 0 solid #000;
                    border-width: 2px 0 0 2px;
                }
            }
        }

        .ant-calendar-body {
            .ant-calendar-disabled-cell,
            .ant-calendar-selected-day {
                .ant-calendar-date {
                    &::before {
                        background: none;
                    }
                }
            }
        }
    }
}
.blueHeaderModal {
    .ant-modal-body {
        padding: 0 !important;
    }
    .ant-modal-confirm-title {
        color: white !important;
        background: #00a6ff;
        padding: 0.8rem;
        border-radius: 10px 10px 0 0;
    }
    .ant-modal-confirm-content {
        padding: 0 30px;
    }
    .ant-modal-confirm-btns {
        margin-bottom: 20px;
        padding: 0 30px;
        .ant-btn {
            border: 1px solid #00a6ff;
            color: #00a6ff;
        }
        .ant-btn-primary {
            color: #fff !important;
        }
    }
}

.settingRequirement {
    background: #f5f5f5;
    padding: 0.6rem;
    border-radius: 0.4rem;
    color: #8f8f8f;
}
.verificationSubmitBtn {
    .ant-btn-primary[disabled],
    .ant-btn-primary[disabled]:hover {
        background: #cccccc;
        color: #ffffff;
        border-color: #cccccc;
    }
    .ant-btn-primary,
    .ant-btn-primary:hover,
    .ant-btn-primary:focus {
        background: #35c95b;
        border-color: #35c95b;
    }
}
.addBankModal {
    .ant-modal-body {
        padding: 28px 30px;

        .ant-row {
            &.ant-form-item:last-child {
                margin-bottom: 0;
            }
        }
    }
    .ant-form-item-required::before {
        content: "";
    }
    .ant-input[disabled] {
        color: #999999;
        background-color: #efeff4;
    }
    .TextLightYellow {
        text-align: left;
        margin-bottom: 20px;
    }
    .forAddBankCard {
        .ant-select {
            .ant-select-selection {
                display: flex;
                align-items: center;
                height: 100%;

                .ant-select-selection__rendered {
                    margin-left: 20px;
                }
            }
        }
        .ant-select-disabled .ant-select-selection .ant-select-arrow {
            display: block;
        }
    }
    .ant-form-explain {
        order: 1 !important;
        text-align: left;
        background: #fee0e0;
        color: #eb2121;
        border-radius: 5px;
        padding: 0.4rem 0.6rem;
        margin-top: 0.3rem !important;
    }

    .Item-bankName .ant-select-dropdown-menu-item .notfind {
        width: 100%;
        text-align: center;
        min-height: 195px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 14px;
        display: flex;

        img {
            width: 60px;
            height: 60px;
        }

        p {
            font-size: 14px;
            color: #bcbec3;
            line-height: 36px;
        }
    }

    .bankNameSelect {
        position: relative;
        // &::after {
        //     content: "";
        //     position: absolute;
        //     width: 100%;
        //     height: 5px;
        //     background-color: #fff;
        // }
        // &.open {
        //     .ant-select-selection {
        //         border: 0;
        //         border-radius: 8px 8px 0 0;
        //         box-shadow: 0 0 1px 1px #0000001a, 0 1px 2px 0 #00000014, 0 2px 8px 2px #0000000d;
        //     }
        // }
        .ant-select-selection {
            border: 1px solid #cccccc;
            box-shadow: none;
            border-radius: 8px;
            height: 45px;
            // width: 96%;
            // margin: 0 2%;
            .ant-select-selection-selected-value {
                width: 100%;
                padding: 12px 30px 12px 46px;
                line-height: 21px;
                .bank-image {
                    position: absolute;
                    left: 20px;
                    width: 18px;
                    height: 18px;
                    top: 50%;
                    transform: translateY(-50%);
                }
                .name {
                    width: 100%;
                    text-align: left;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            .ant-select-selection__rendered {
                position: relative;
                line-height: 45px;
                margin: 0;
                .ant-select-selection__placeholder {
                    left: 12px;
                }
            }
            .ant-select-search__field__wrap::before {
                content: "";
                width: 16px;
                height: 16px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                background-image: url(/th/img/icon/search.svg);
            }
            .ant-select-arrow {
                font-size: 14px;
            }
        }
    }

    .bankNameSelectDropdown {
        .ant-select-dropdown-menu-item-active:not(
                .ant-select-dropdown-menu-item-disabled
            ) {
            background-image: url("/th/img/icon/miniTick.svg") !important;
            background-repeat: no-repeat;
            background-position: 95%;
            transition: none;
        }
        
        top: 0px !important;
        border-top: 0;
        .placeholder-container {
            height: 45px;
            padding: 16px 16px 8px;
            color: #999999;
            text-align: left;
        }
        &.focus {
            .search-container {
                border-color: #00a5fd;
            }
        }
        .search-container {
            position: relative;
            width: calc(100% - 30px);
            height: 30px;
            margin: 8px 15px;
            border-radius: 4px;
            border: 1px solid #cccccc;
            display: flex;
        }
        .search-input {
            height: 28px;
            padding: 4px 24px 4px 47px;
            border: 0px;
            box-shadow: none;
        }
        .search-icon {
            position: absolute;
            left: 15px;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none; /* 使图标不可点击 */
            z-index: 1;
        }
        .search-clear {
            position: absolute;
            right: 8px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 16px;
            color: #cccccc;
            line-height: 16px;
            height: 16px;
            cursor: pointer;
        }
        .ant-select-dropdown-menu {
            max-height: 215px;
        }
        .ant-select-dropdown-menu-item {
            position: relative;
            display: flex;
            gap: 10px;
            text-align: left !important;
            padding-left: 26px;
            align-items: center;
            min-height: 45px;
            line-height: normal;
            white-space: pre-line;
            .bank-image > div {
                display: flex;
                img {
                    width: 18px;
                    height: 18px;
                }
            }
            .name {
                max-width: 240px;
            }
        }
    }
}

// 客製首選帳戶、安全提问下拉選單
.safety-question-dropdown,
.account-dropdown {
    .ant-select-dropdown-menu {
        max-height: unset;

        li {
            text-align: start !important;
        }
    }
}
.btn-content-set {
    text-align: left;
    p {
        margin-bottom: 30px;
    }
    .red-set {
        color: #f11818;
    }
}
.btn-content-file {
    background-color: #f5f5f5;
    text-align: right;
    border-radius: 10px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    margin-bottom: 10px;
    .red-set {
        color: #f11818;
        font-size: 12px;
    }
    .imgname {
        text-align: left;
        width: 246px;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    button {
        border-radius: 0px 8px 8px 0px;
        border: 0;
        box-shadow: unset;
    }
}
.btn-content {
    display: flex;
    margin-top: 30px;
    .ant-btn-background-ghost {
        margin-right: 10px;
    }
}

.address-management {
    .TextLightYellow {
        padding: 10px 16px;
        margin: 30px 0px;
    }
    .address-list-container {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        word-break: break-all;
        .address-list-item {
            width: 242px;
            min-height: 130px;
            height: fit-content;
            overflow: hidden;
            padding: 16px;
            border-radius: 8px;
            border: 1px solid #e3e3e8;
            font-size: 12px;
            color: #999999;
            cursor: pointer;
            .info-name-edit {
                display: flex;
                justify-content: space-between;
                margin-bottom: 8px;
                .info-name {
                    color: #222222;
                    font-size: 16px;
                    line-height: 22px; /* 137.5% */
                    max-width: 120px;
                }
                .info-edit {
                    .default {
                        color: #0ccc3c;
                        line-height: 22px; /* 183.333% */
                        margin-right: 8px;
                        position: relative;
                        &::before {
                            content: "";
                            background-image: url("/th/img/icon/greenTick.svg");
                            background-repeat: no-repeat;
                            width: 14px;
                            height: 14px;
                            position: absolute;
                            left: -18px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }
            }
            .info-phone {
                margin-bottom: 16px;
            }
        }
    }
    .add-address-item {
        width: 242px;
        height: 130px;
        margin-right: 0px;
        color: #00a6ff;
        text-align: center;
        border-radius: 8px;
        padding: 10px;
        background: #fff;
        border: 2px dashed #00a6ff;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
            margin-left: 8px;
        }
    }
}

.resubmit-modal {
    .ant-modal-close-x {
        display: none;
    }
    .ant-modal-body .ant-modal-confirm-body-wrapper {
        .ant-modal-confirm-body .ant-modal-confirm-content {
            margin: 0px;
            padding: 30px 30px 24px;
            .remind {
                text-align: center;
                margin-bottom: 10px;
            }
            ul {
                padding-left: 20px;
                > li {
                    list-style: decimal;
                }
            }
        }
        .ant-modal-confirm-btns {
            display: flex;
            gap: 20px;
            margin: 0px;
            padding: 0 30px 30px;
            width: 100%;
            button {
                width: 100%;
                height: 40px;
                margin: 0px;
            }
        }
    }
}

.resubmit-info-modal {
    .ant-modal-content {
        .ant-modal-close {
            display: block;
        }
        .ant-modal-body .ant-modal-confirm-body-wrapper {
            .ant-modal-confirm-body .ant-modal-confirm-content {
                margin: 0px;
                padding: 28px 30px 24px;
            }
            .ant-modal-confirm-btns {
                margin: 0px;
                padding: 0px 30px 28px;
                width: 100%;
            }
        }
    }
}
